<!--
  목적 : 평가계획 수립 - 평가계획 수립 관리
  Detail : 평가계획 등록/조회 화면
  *
  examples:
  *
  -->
<template>
  <b-container fluid>
    <b-row class="mt-3">
      <b-col sm="12">
        <b-col sm="12" class="px-0 text-center">
          <qrcode-stream :track="this.paintOutline" @init="onInit" @decode="onDecode" style="width: 70% !important;margin:0 auto;"></qrcode-stream>
          <div class="qrnoti" v-show="!qrtext">{{$language('QR코드를 스캔하시면 해당업무로 이동할 수 있는 버튼이 생성됩니다.')}}</div>
          <c-btn
            v-show="qrtext"
            size="xl"
            :label="qrtext"
            :dense="false"
            icon="security_update_good"
            style="margin-top:20px;height: 40px"
            color="amber-7"
            @btnClicked="linkGo(qrtextlink)" />
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  /* attributes: name, components, props, data */
  name: 'qrreader',
  components: { QrcodeStream },
  props: {
  },
  data () {
    return {
      qrtext: '',
      qrtextlink: '',
    }
  },
  //* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
    this.init();
  },
  mounted () {
  },
  beforeDestroy () {
  },
  //* methods */
  methods: {
    init () {
    },
    async onInit () {
      // show loading indicator
      try {
        // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          alert('카메라 엑세스 권한 부여 필요함');
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    },
    onDecode (decodedString) {
      let swp = 'sop/swp/safeWorkPermit';
      let edu = 'sop/edu/result/educationResult';
      let equip = 'sop/mim/inspection/equipmentQrSelect';
      let tbm = 'sai/tbm/tbm';
      this.qrtextlink = decodedString;
      if (decodedString.indexOf(swp) > -1) {
        this.qrtext = '작업허가서 바로가기';
      } else if (decodedString.indexOf(edu) > -1) {
        this.qrtext = '교육참석 바로가기';
      } else if (decodedString.indexOf(equip) > -1) {
        this.qrtext = '설비관련 점검 바로가기';
      }  else if (decodedString.indexOf(tbm) > -1) {
        this.qrtext = 'TBM 바로가기';
      } else {
        this.qrtext = '관련된 업무가 없습니다.';
        this.qrtextlink = '';
      }
    },
    paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    linkGo(url) {
      if (url) {
        var domains = url.split('/');
        var domain = domains[0] + '//' + domains[2];
        this.$comm.movePage(this.$router, url.replace(domain,''))
      }
    }
  }
};
</script>